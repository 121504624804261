import { productLink } from '@components/config/helpers';
import Box  from '@mui/material/Box';
import Typography  from '@mui/material/Typography';
import Link from "next/link"

const CategoryProductPriceLabels = (props) => {
    
    const {
        product_name,
        section_slug,
        product_slug,
        long_term,
    } = props;

    return (
        <Box sx={{minHeight: '72px'}} className="mt-3">
            <Link shallow={true} className="category_anchor"  href={productLink(section_slug, product_slug, long_term)}>
                <Typography variant="h6" component="div" sx={{minHeight:55, color:(theme)=>theme.palette.primary.main}} >
                    {product_name}
                </Typography>
            </Link>   
        </Box>
    )
}

export default CategoryProductPriceLabels